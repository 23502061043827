html, body {
  height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  background-color: #cccccc;
  background-image: url('background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.green-back {
  background-color: rgba(187, 204, 187, .6);
  border: 2px solid gray;
}

.red-back {
  background-color: rgba(204, 187, 187, .6);
  border: 2px solid gray;
}

.choice-back {
  background-color: rgba(100, 100, 100, .2);
}

.login-back {
  background-color: rgba(0, 0, 100, .15);
  border: 1px solid gray;
  width: 400px;
  height: 100px;
  border-radius: 6px;
  text-align: center;
}